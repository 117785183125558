













































import { Component, Mixins } from "vue-property-decorator";
import AuthService from "@/services/AuthService";
import RulesMixin from "@/mixins/RulesMixin";
import AppFormWrapper from "@/layouts/AppFormWrapper.vue";

@Component({
  components: {
    AppFormWrapper,
  },
})
export default class Login extends Mixins(RulesMixin) {
  email = "";
  password = "";
  showPassword = false;

  private async handleSubmit(): Promise<any> {
    try {
      await AuthService.login(this.email, this.password);
      await this.$router.push("/home");
    } catch (err) {
      this.$snackbarError(this.$tc("auth.failed", 1));
    }
  }

  mounted() {
    const email = Array.isArray(this.$route.query.email)
      ? this.$route.query.email[0] || false
      : this.$route.query.email;
    if (email) {
      this.email = email;
    }
  }
}
